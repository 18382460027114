import React from 'react';

import AppContainer from '../AppContainer/AppContainer';
import { useTranslation } from '../../hooks/translation';
import AppImage from '../AppImage';

import styles from './AppPartner.module.scss';

const Partners = [
  { src: '/images/partners/georgia-travel.png', alt: 'georiga travel logo' },
  { src: '/images/partners/batumi-travel.png', alt: 'visit batumi logo' },
  { src: '/images/partners/desk.png', alt: 'euro desk logo' },
  { src: '/images/partners/youth-card.png', alt: 'youtcard logo' },
  { src: '/images/partners/edec.png', alt: 'edec logo' },
  { src: '/images/partners/actio.png', alt: 'impact fund logo' },
  { src: '/images/partners/mfa.png', alt: 'ministry of internal affiars logo' },
  { src: '/images/partners/csrdg.png', alt: 'csrdg logo' },
];

export default function AppPartner() {
  const { translate } = useTranslation();

  return (
    <section className={styles.AppPartner}>
      <AppContainer>
        <h2 className={styles.AppPartner__Title}>{translate('home_partners')}</h2>
        <div className={styles.AppPartner__List}>
          {Partners.map((item, index) => (
            <div key={index} className={styles.AppPartner__Item}>
              <AppImage src={item.src} objectFit="contain" title={item.alt} alt={item.alt} />
            </div>
          ))}
        </div>
      </AppContainer>
    </section>
  );
}
